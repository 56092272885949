export const sbxButton = {
  route: () => import('./SbxButton.vue'),
  propTypes: {
    buttonId: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      default: 'Title goes here',
    },
    primaryColor: {
      type: String,
      default: '#000000',
    },
    secondaryColor: {
      type: String,
      default: '#eb5f40',
    },
    buttonClick: {
      type: Function,
      required: true,
    },
    aspectType: {
      type: String,
      default: 'button',
      validator: function (value) {
        return ['pill', 'button'].includes(value);
      },
    },
  },
};
