export const heroBanner = {
  route: () => import('./HeroBannerPattern'),
  propTypes: {
    pattern: {
      type: String,
      default: '',
    },
    bannerLink: {
      type: String,
      default: '',
    },
    overlay: {
      type: String,
      validator: function (value) {
        return ['full', 'half'].includes(value);
      },
      required: true,
    },
    maxViewSize: {
      type: String,
      default: 'contained',
      validator: function (value) {
        return ['fullWidth', 'wide', 'contained', 'mobile'].includes(value);
      },
    },
    heroImage: {
      type: Object,
      required: true,
    },
    buttons: {
      type: Array,
      default: [
        {
          viewSize: ['mobile', 'contain'],
          buttonText: 'buttonTextMobile',
          buttonLink: 'buttonLink',
          buttonBorder: '#006648',
        },
        {
          viewSize: [],
          buttonText: 'buttonText1',
          buttonLink: 'buttonLink1',
          buttonBorder: '#4c50bd',
        },
      ],
    },
    headerSection: {
      type: Array,
      required: true,
    },
    overlayBgColor: {
      type: String,
      default: 'transparent',
    },
    mobileBtnSize: {
      type: String,
      default: 'L',
      validator: function (value) {
        return ['S', 'M', 'L'].includes(value);
      },
    },
  },
  devProps: {
    overlay: 'full',
    pattern: 'https://wallpaperaccess.com/full/1608694.jpg',
    heroImage: {
      mobile: 'https://media.smartbox.com/media/war/2021/homepage/FR_Spa_4_HP_768x800_v2.jpg',
      contained: 'https://media.smartbox.com/media/dk/2021/hp-banners/DK_Birthday_HP_1230x450.jpg',
      'full-width': 'https://media.smartbox.com/media/ds/homepage/2021/DKTES_Summer_GF_1920x840.jpg',
      base: 'https://media.smartbox.com/media/df/static/event-page/gift-ideas/DKTFR_banner_1920x617.jpg',
      timer: {
        enabled: "false",
        year: "2024",
        month: "6",
        day: "4",
        hour: "0",
        minute: "0",
        color: "#000",
        bgColor: "#fff"
      }
    },
    headerSection: [
      {
        viewSize: ['mobile'],
        headerTitle: 'headerTitleMobile',
        headerDescription: 'headerDescriptionMobile',
        headerColor: '#000',
        textAlign: 'left',
        MobileVerticalAlign: 'top',
        buttonTextColour: '#FFE598',
        buttonBgColour: '#006648',
        h1Title: 'true'
      },
      {
        viewSize: [],
        headerTitle: 'headerTitle',
        headerDescription: 'headerDescription',
        headerColor: '#fff',
        textAlign: 'center',
        buttonTextColour: '#FFE598',
        buttonBgColour: '#4c50bd',
        h1Title: 'false'
      },
    ],
  }
};
