import { brands, locales } from './brands.js';

/**
 * @description Array of supported locales. Used in  supportsLocale()
 * @private
 */
const supportedLocales = [
  {
    brand: brands.smartbox,
    locale: locales.french,
  },
  {
    brand: brands.smartbox,
    locale: locales.italian,
  },
  {
    brand: brands.smartbox,
    locale: locales.spanish,
  },
  {
    brand: brands.smartbox,
    locale: locales.swissFrench,
  },
  {
    brand: brands.smartbox,
    locale: locales.swissGerman,
  },
  {
    brand: brands.smartbox,
    locale: locales.swissItalian,
  },
  {
    brand: brands.smartbox,
    locale: locales.swedish,
  },
  {
    brand: brands.smartbox,
    locale: locales.danish,
  },
  {
    brand: brands.smartbox,
    locale: locales.german,
  },
  {
    brand: brands.lavidaesbella,
    locale: locales.spanish,
  },
  {
    brand: brands.dakotaFrance,
    locale: locales.french,
  },
  {
    brand: brands.dakotaSpain,
    locale: locales.spanish,
  },
  {
    brand: brands.bongo,
    locale: locales.dutch,
  },
  {
    brand: brands.bongo,
    locale: locales.belgianDutch,
  },
  {
    brand: brands.bongo,
    locale: locales.belgianFrench,
  },
  {
    brand: brands.cadeaubox,
    locale: locales.belgianFrench,
  },
  {
    brand: brands.cadeaubox,
    locale: locales.belgianDutch,
  },
  {
    brand: brands.emozione,
    locale: locales.italian,
  },
];

/**
 Function to check supported locales. Used in  supportsLocale()
 * @private
 * @param brand string
 * @param locale string
 */
export function supportsLocale(brand, locale) {
  return !!supportedLocales.find(item => item.brand === brand && item.locale === locale);
}
