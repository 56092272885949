/**
 * @private
 * @description Objects used as part of currencyFormat
 */
export const currencyStyles = {
  pre: 'pre',
  post: 'post',
  postS: 'postS',
};

export const currencies = {
  EUR: '€',
  CHF: 'CHF',
  SEK: 'kr',
  DKK: 'kr.',
};

export const delimiters = {
  comma: ',',
  dot: '.',
};

export const thousandsSeparators = {
  space: ' ',
  dot: '.',
  comma: ',',
};
