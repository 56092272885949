import { prodHosts, preProdHosts, baseUrl, localHostPatterns } from '../urlUtils';

/**
 * @description Object of environments. Used in  supportsLocale()
 * @private
 */
export const environments = {
  production: 'prod',
  preproduction: 'preprod',
  devint: 'devint',
  local: 'local',
};

/**
 * @description Function to determine environment based on baseURL. Used in getSearchURL /  brandingImporter()
 * @private
 returns @string
 */
export function environmentMapper(url = null) {
  const baseURL = () => {
    const base = baseUrl(url);
    if (base !== '/') {
      const url = new URL(base);
      return url.hostname;
    } else {
      return window.location.hostname;
    }
  };

  if (prodHosts.includes(baseURL())) {
    return environments.production;
  }

  if (preProdHosts.includes(baseURL())) {
    return environments.preproduction;
  }

  // local will now point to devint, disabling so no need for a check against localhost patterns
  // keeping the functionlity here but commented out incase it needs to be re-instated

  const hasLocalMatches = localHostPatterns.find(pattern => {
    return pattern.test(baseURL());
  });

  if (hasLocalMatches) {
    return environments.local;
  } else {
    return environments.devint;
  }
}
