import { brands, locales } from '../localization/brands';
import { currencies, currencyStyles, delimiters, thousandsSeparators } from './constants';

/***
 * @description Array of different currency formats. Used in  currencyInfo / brandingImporter()
 * @private
 */
export const currencyFormat = [
  {
    brand: brands.smartbox,
    locale: locales.french,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.space,
  },
  {
    brand: brands.smartbox,
    locale: locales.italian,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.smartbox,
    locale: locales.spanish,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.post,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.smartbox,
    locale: locales.swissFrench,
    currency: currencies.CHF,
    currencyStyle: currencyStyles.pre,
    delimiter: delimiters.dot,
    thousands: thousandsSeparators.comma,
  },
  {
    brand: brands.smartbox,
    locale: locales.swissGerman,
    currency: currencies.CHF,
    currencyStyle: currencyStyles.pre,
    delimiter: delimiters.dot,
    thousands: thousandsSeparators.comma,
  },
  {
    brand: brands.smartbox,
    locale: locales.swissItalian,
    currency: currencies.CHF,
    currencyStyle: currencyStyles.pre,
    delimiter: delimiters.dot,
    thousands: thousandsSeparators.comma,
  },
  {
    brand: brands.smartbox,
    locale: locales.german,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.space,
  },
  {
    brand: brands.smartbox,
    locale: locales.swedish,
    currency: currencies.SEK,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.space,
  },
  {
    brand: brands.smartbox,
    locale: locales.danish,
    currency: currencies.DKK,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.lavidaesbella,
    locale: locales.spanish,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.post,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.dakotaSpain,
    locale: locales.spanish,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.post,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.dakotaFrance,
    locale: locales.french,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.space,
  },
  {
    brand: brands.bongo,
    locale: locales.dutch,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.bongo,
    locale: locales.belgianDutch,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.bongo,
    locale: locales.belgianFrench,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.cadeaubox,
    locale: locales.belgianFrench,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.cadeaubox,
    locale: locales.belgianDutch,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.emozione,
    locale: locales.italian,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.dot,
  },
  {
    brand: brands.smartbox,
    locale: locales.irish,
    currency: currencies.EUR,
    currencyStyle: currencyStyles.postS,
    delimiter: delimiters.comma,
    thousands: thousandsSeparators.space,
  },
];
