export const usp = {
  route: () => import('./Usp.vue'),
  propTypes: {
    isSlim: {
      type: String,
      default: 'false',
    },
    title: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    fontColor: {
      type: String,
      default: '#1C1C1C',
    },
    mobileScroll: {
      type: String,
      default: 'false',
    },
    align: {
      type: String,
      default: 'center'
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        icon: 'si-usp-secure-payment',
        title: '100% veilig betalen',
        subtitle: 'Persoonsgegevens worden vertrouwelijk behandeld'
      },
      {
        icon: 'si-usp-exchange',
        title: 'Onbeperkt en gratis omruilen',
        subtitle: 'Makkelijk en snel omruilen '
      },
      {
        icon: 'si-usp-free-delivery',
        title: 'Geldig tot 2026',
        subtitle: 'Zo heb je alle tijd om te boeken en te genieten'
      },
      {
        icon: 'si-box',
        title: 'Geweldige klantbeoordelingen',
        subtitle: 'Miljoenen tevreden klanten'
      },
      {
        icon: 'si-usp-great-reviews',
        title: 'Meer dan 10.000 belevenissen in de Benelux',
        subtitle: 'Voor ieder wat wils'
      },
      {
        icon: 'si-usp-validity',
        title: 'Verstuur per e-mail',
        subtitle: 'Ontvang jouw cadeaubon direct in je mailbox'
      },
    ]
  }
};
