export const promotionBanner = {
  route: () => import('./PromotionBanner.vue'),
  propTypes: {
    text: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: '#393939',
    },
    backgroundColor: {
      type: String,
      default: '#FFC0CB',
    },
    icon: {
      type: String,
      default: 'si-delivery',
    },
    flipIcon: {
      type: String,
      default: 'false',
      validator: function (value) {
        return ['false', 'true'].includes(value);
      },
    },
    timer: {
      type: Object,
      required: false,
      default: {
        enabled: "false",
        year: "2023",
        month: "8",
        day: "4",
        hour: "0",
        minute: "0",
        color: "#000",
        bgColor: "#fff",
      }
    }
  },
  devProps: {
    text: 'Évitez toute déception ! Commandez avant le <b>10/02 à 13h</b> pour recevoir votre cadeau à temps pour la Saint-Valentin.'
  }
};
