export const sbxLazyImg = {
  route: () => import('./SbxLazyImg.vue'),
  propTypes: {
    imageUrl: {
      type: String,
      required: true,
      default: '',
    },
    classImg: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: Number,
      required: false,
      default: 640,
    },
    height: {
      type: Number,
      required: false,
      default: 480,
    },
  },
};
