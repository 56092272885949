export const uspRibbon = {
  route: () => import('./UspRibbon.vue'),
  propTypes: {
    primaryColor: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    fontColor: {
      type: String,
      default: '',
    },
    mobileLoop: {
      type: Number,
      default: 5000,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        title: 'ÉCHANGE GRATUIT ET ILLIMITÉ*',
        icon: 'si-exchange-2',
      },
      {
        title: "VALABLE JUSQU'EN 2025***",
        icon: 'si-calendar-4',
      },
      {
        title: 'PLUS DE 100 000 EXPÉRIENCES EN FRANCE**',
        icon: 'si-map-3',
      },
    ]
  }
};
