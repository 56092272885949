export const subCategoriesLinks = {
  route: () => import('./SubCategoriesLinks.vue'),
  propTypes: {
    subCatAspect: {
      type: String,
      default: 'pill',
      validator: function (value) {
        return ['pill', 'button'].includes(value);
      },
    },
    subCatTextColor: {
      type: String,
      default: '#000000',
    },
    subCatHoverTextColor: {
      type: String,
      default: '#ff4c00',
    },
    subCatBackgroundColor: {
      type: String,
      default: '#ffffff',
    },
    subCatHoverBackgroundColor: {
      type: String,
      default: '#ffffff',
    },
    subCatBorderColor: {
      type: String,
      default: '#ff4c00',
    },
    subCatHoverBorderColor: {
      type: String,
      default: '#ff4c00',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        title: 'Ferrari',
        link: 'nos-smartbox/sejour/',
      },
      {
        title: 'Porsche',
        link: 'nos-smartbox/bien-etre/',
      },
      {
        title: 'Barcelona',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        title: 'Lamborghini',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        title: 'Subaru',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        title: 'Racing Tracks',
        link: 'nos-smartbox/gastronomie/',
      },
    ]
  }
};
