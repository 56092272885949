/**
 * @description Array of development URLs. Used for sessionStorage locally for utilisng brandingImporter()
 */

export const developmentUrls = [
  'https://dev.smartbox.com/fr/',
  'https://dev.bongo.be/',
  'https://dev.dakotabox.es/',
  'https://dev.dakotabox.fr/',
  'https://dev.emozione3.it/',
  'https://dev.lavidaesbella.es/',
  'https://dev.cadeaubox.be/',
  'https://dev.smartbox.com/ie',
  'https://dev.smartbox.com/it/',
  'https://dev.smartbox.com/de/',
  'https://dev.smartbox.com/dk/',
  'https://dev.smartbox.com/se/',
  'https://dev.smartbox.com/es/',
  'https://dev.smartbox.com/ch/',
  'https://dev.smartbox.com/ch/fr/',
  'https://dev.smartbox.com/ch/it/',
  'https://dev.smartbox.com/ch/de/',
  'https://dev.bongo.be/fr/',
  'https://dev.bongo.nl/',
  'https://dev.cadeaubox.be/fr/',
];
