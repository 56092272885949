export const newsletter = {
  route: () => import('./Newsletter.vue'),
  propTypes: {
    backgroundColor: {
      type: String,
      default: '#4c509d',
    },
    textColor: {
      type: String,
      default: '#ffd300',
    },
    emailTitleText: {
      type: String,
      required: true,
    },
    emailDescriptionText: {
      type: String,
      required: true,
    },
    emailSignUpText: {
      type: String,
      required: true,
    },
    emailDisclaimerText: {
      type: String,
      required: true,
    },
    emailImg: {
      type: String,
      default: '',
      required: true,
    },
    checkoutImg: {
      type: String,
      required: true,
    },
    checkoutTitleText: {
      type: String,
      required: true,
    },
    checkoutDescriptionText: {
      type: String,
      required: true,
    },
    discountCode: {
      type: String,
      default: '',
    },
    useCodeText: {
      type: String,
      required: true,
    },
    copyCodeText: {
      type: String,
      required: true,
    },
    codeCopiedText: {
      type: String,
      required: true,
    },
  },
  devProps: {
    titleText: 'Voulez-vous être tenu(e) au courant?',
    emailTitleText: 'Voulez-vous être tenu(e) au courant ?',
    descriptionText: 'Inscrivez-vous à notre newsletter et recevez des rabais exclusivement réservés aux abonnés de Smartbox !',
    emailDescriptionText: 'Inscrivez-vous à notre newsletter et recevez des rabais exclusivement réservés aux abonnés de Smartbox !',
    emailPlaceholderText: 'pierre@gmail.com',
    registerButtonText: 'S’abonner',
    emailSignUpText: 'S’abonner',
    disclaimerText:
      "Votre adresse e-mail est collectée par Smartbox Group Ltd. dans le seul but d'envoyer des newsletters et des promotions Smartbox. Vous pouvez vous désabonner de ces e-mails à n'importe quel moment en cliquant sur le lien en bas de chacun d'entre eux.",
    emailDisclaimerText:
      "Votre adresse e-mail est collectée par Smartbox Group Ltd. dans le seul but d'envoyer des newsletters et des promotions Smartbox. Vous pouvez vous désabonner de ces e-mails à n'importe quel moment en cliquant sur le lien en bas de chacun d'entre eux.",
    emailImg: 'https://media.smartbox.com/media/war/2023/home/SBX_Winter23_SubscriptionForm_615x390.jpg',
    checkoutImg: 'https://media.smartbox.com/media/war/2023/home/SBX_Winter23_SubscriptionForm_615x390.jpg',
    checkoutTitleText: 'Merci de votre inscription !',
    checkoutDescriptionText:
      'Vous serez désormais informé(e) de nos nouveautés et meilleures offres du moment pour vous guider dans votre quête du cadeau idéal.',
    useCodeText: '',
    copyCodeText: '',
    codeCopiedText: '',
  },
};
