// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"brandPrimaryColor1": "var(--brand-primary-color-1)",
	"brandPrimaryColorDarker10": "var(--brand-primary-color-darker-10)",
	"brandHighlightCardBg": "var(--brand-highlight-card-background)",
	"brandSectionBg": "var(--brand-section-background)",
	"brandIconGreyShade1": "var(--brand-icon-grey-shade-1)",
	"brandSecondaryIconGreen": "var(--brand-secondary-icon-green)",
	"brandSearchBarBackground": "var(--brand-search-bar-background)",
	"brandNewsletterFooterBackground": "var(--brand-newsletter-footer-background)",
	"brandHeaderBackground": "var(--brand-header-background)",
	"brandHeaderColor1": "var(--brand-header-color-1)",
	"brandHeaderColorText1": "var(--brand-header-color-text-1)",
	"brandHeaderColorText2": "var(--brand-header-color-text-2)",
	"brandHeaderColorTooltipText": "var(--brand-header-color-tooltip-text)",
	"brandFooterBackground": "var(--brand-footer-background)",
	"brandFooterTitle": "var(--brand-footer-title)",
	"brandFooterContent": "var(--brand-footer-content)",
	"brandFooterIcon": "var(--brand-footer-icon)",
	"brandDarkBlue": "var(--brand-dark-blue)",
	"brandLightBlue": "var(--brand-light-blue)",
	"brandDarkGreen": "var(--brand-dark-green)",
	"brandBrightGreen": "var(--brand-bright-green)",
	"brandLightGreen": "var(--brand-light-green)",
	"brandPink": "var(--brand-pink)",
	"brandYellowShade2": "var(--brand-yellow-shade-2)",
	"brandPurple": "var(--brand-purple)",
	"brandFontBold": "var(--brand-font-bold)",
	"brandFontMedium": "var(--brand-font-medium)",
	"brandFontRegular": "var(--brand-font-regular)",
	"collectionSuperdarkgrey": "#1c1c1c",
	"collectionDarkgrey": "#393939",
	"collectionMediumGrey": "#595959",
	"collectionLightGrey": "#ebebeb",
	"collectionSuperLightGrey": "#fafafa",
	"collectionBlack": "#000",
	"collectionWhite": "#fff",
	"collectionPromoRed": "#df1d42",
	"collectionPromoRed2": "#e30421",
	"collectionColourError": "#d85353",
	"collectionColourHighlight": "#f6b900",
	"collectionColourInformation": "#0091ff",
	"collectionColourSuccess": "#48b488",
	"collectionColourTurqouise1": "#00c4b3",
	"collectionColourWarning": "#feef6d",
	"collectionYellowOrange": "#f49800"
};
module.exports = exports;
