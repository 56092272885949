export const newsletterPopup = {
  route: () => import('./NewsletterPopup.vue'),
  propTypes: {
    emailTitleText: {
      type: String,
      default: 'header_text',
      required: true,
    },
    emailDescriptionText: {
      type: String,
      default: 'description_text',
      required: true,
    },
    emailSignUpText: {
      type: String,
      default: 'sign_up_text',
      required: true,
    },
    emailImg: {
      type: String,
      default: '',
      required: true,
    },
    emailLegalText: {
      type: String,
    },
    checkoutImg: {
      type: String,
      default: '',
      required: true,
    },
    checkoutTitleText: {
      type: String,
      default: 'header_text',
      required: true,
    },
    checkoutDescriptionText: {
      type: String,
      default: 'description_text',
      required: true,
    },
    checkoutRulesText: {
      type: String,
    },
    discountCode: {
      type: String,
      default: '',
      required: false,
    },
    useCodeText: {
      type: String,
      default: 'use_code_text',
      required: true,
    },
    copyCodeText: {
      type: String,
      default: 'copy_code_text',
      required: true,
    },
    codeCopiedText: {
      type: String,
      default: 'code_copied_text',
      required: true,
    },
    displayDelaySeconds: {
      type: Number,
      default: 10,
    },
    redisplayAfterDays: {
      type: Number,
      default: 32,
    },
    // colors
    primaryColor: {
      type: String,
      default: '#7e2182',
    },
    secondaryColor: {
      type: String,
      default: '#ffd300',
    },
    tertiaryColor: {
      type: String,
      default: '#ffffff',
    },
    quaternaryColor: {
      type: String,
      default: '#ffe598',
    },
    quinaryColor: {
      type: String,
      default: '#e4003a',
    },
    senaryColor: {
      type: String,
      default: '#006648',
    },
    emailCloseBtnColor: {
      type: String,
      default: '#ffffff',
    },
    checkoutCloseBtnColor: {
      type: String,
      default: '#000000',
    },
  },
};
