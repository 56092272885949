export const merchThinBlock = {
  route: () => import('./MerchThinBlock.vue'),
  propTypes: {
    cta: {
      type: Object,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'normal',
    }
  },
  devProps: {
    cta: {
      title: 'Click here',
      color: 'white',
      btnBgColor: 'green',
      bgColor: 'pink',
      link: 'fr/',
      btnAlign: 'right',
      chervonColor: 'blue',
      isChervon: true,
      isOnlyChervon: false,
      mobilefullWidthBtn: false,
      grid: '1',
    },
    content: {
      title: 'title',
      isTitleBold: true,
      subTitle: "you can use the data-src attribute to set the image's source",
      color: 'white',
      bgColor: 'red',
      textAlign: 'left',
      grid: '1',
    },
    image: {
      mobile: 'https://media.smartbox.com/media/war/2021/homepage/FR_Spa_4_HP_768x800_v2.jpg',
      contained: 'https://media.smartbox.com/media/dk/2021/hp-banners/DK_Birthday_HP_1230x450.jpg',
      'full-width': 'https://media.smartbox.com/media/ds/homepage/2021/DKTES_Summer_GF_1920x840.jpg',
      base: 'https://media.smartbox.com/media/df/static/event-page/gift-ideas/DKTFR_banner_1920x617.jpg',
    },
  },
};
