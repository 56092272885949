export const articleList = {
  route: () => import('./ArticleList.vue'),
  propTypes: {
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        backgroundColor: '#fff',
        imageUrl: 'https://media.smartbox.com/media/es/static/event-page/unesco/ES_Unesco_LP_9_650x450.jpg?filters:quality(90)',
        title: 'PALACIO DE LA MÚSICA Y HOSPITAL DE SANT PAU',
        titleColor: '',
        subTitle:
          'Este teatro emblemático de espectacular belleza se sitúa en el centro de Barcelona. Fíjate en su primitiva fachada, grandes muros de cristal, mosaicos, estructuras de hierro forjado y la sala de conciertos con su gran claraboya de esta joya del modernismo.',
        expandButtonTitle: 'Leer más',
        description:
          'El Hospital de Sant Pau se compone de un conjunto de edificios gótico, neogótico, mozárabe y germánico. Consta de un edificio principal y de 27 pabellones contiguos para cada una de las especialidades. Actualmente ya no está en uso así que no te lo puedes perder, ¡ven a visitarlo!',
        ctaTitle: 'DESCUBRE LA REGIÓN',
        ctaLink: 'actividades-por-regiones-en-espana/cataluna/',
        ctaColor: '',
        imagePosition: 'left',
      },
      {
        backgroundColor: '#EEECE8',
        imageUrl: 'https://media.smartbox.com/media/es/static/event-page/unesco/ES_Unesco_LP_9_650x450.jpg?filters:quality(90)',
        title: 'PALACIO DE LA MÚSICA Y HOSPITAL DE SANT PAU',
        titleColor: '',
        subTitle:
          'Este teatro emblemático de espectacular belleza se sitúa en el centro de Barcelona. Fíjate en su primitiva fachada, grandes muros de cristal, mosaicos, estructuras de hierro forjado y la sala de conciertos con su gran claraboya de esta joya del modernismo.',
        expandButtonTitle: 'Leer más',
        description:
          'El Hospital de Sant Pau se compone de un conjunto de edificios gótico, neogótico, mozárabe y germánico. Consta de un edificio principal y de 27 pabellones contiguos para cada una de las especialidades. Actualmente ya no está en uso así que no te lo puedes perder, ¡ven a visitarlo!',
        ctaTitle: 'DESCUBRE LA REGIÓN',
        ctaLink: 'actividades-por-regiones-en-espana/cataluna/',
        ctaColor: '',
        imagePosition: 'right',
      },
    ]
  }
};
