export const dummyBrochure = {
  route: () => import('./DummyBrochure.vue'),
  propTypes: {
    title: {
      type: String,
      default: 'Title goes here',
      required: true,
    },
    brochureBgColor: {
      type: String,
      default: 'red',
    },
  },
};
