export const chevronLinks = {
  route: () => import('./ChevronLinks.vue'),
  propTypes: {
    title: {
      type: String,
      default: '',
    },
    primaryColor: {
      type: String,
      default: '#FE5816',
    },
    secondaryColor: {
      type: String,
      default: '#343533',
    },
    backgroundColor: {
      type: String,
      default: '#FBF8F9',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        label: 'Dîner inclus',
        link: 'nos-smartbox/sejour/',
      },
      {
        label: 'Séjour Insolite',
        link: 'nos-smartbox/bien-etre/',
      },
      {
        label: ' Séjour de Luxe',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        label: 'Séjours en famille',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        label: 'Séjour détente',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        label: 'Romantique',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        label: '1 nuit',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        label: '2 nuit',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        label: '3 nuit',
        link: 'nos-smartbox/gastronomie/',
      },
      {
        label: '1001 Nuits',
        link: 'nos-smartbox/gastronomie/',
      },
    ]
  }
};
