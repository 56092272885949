import { sbxButton } from './SbxButton';
import { sbxLazyImg } from './SbxLazyImg';
import { productVignette } from '../SharedFe/components/ProductVignette';
import { sbxSpinner } from '../SharedFe/atoms/SbxSpinner';
import { sbxAsset } from './sbxAssetsLibrary';

export default {
  sbxSpinner,
  sbxButton,
  sbxLazyImg,
  productVignette,
  sbxAsset,
  _carouselTemplate: {
    route: () => import('./_carouselTemplate/_carouselTemplateBase.vue'),
    propTypes: { slides: { type: Array, default: [{}, {}, {}] } },
  },
};
