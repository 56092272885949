import { environmentMapper, environments } from './localization/environments';
import { brandingImporter } from './brandingUtils';

const searchURL = process.env.VUE_APP_SEARCH_URL;
const preprodURL = process.env.VUE_APP_PREPROD_URL;
const devintURL = process.env.VUE_APP_DEVINT_URL;

const urlEnvMap = {
  [environments.production]: searchURL,
  [environments.preproduction]: preprodURL,
  [environments.devint]: devintURL,
  [environments.local]: devintURL,
};

export const getSearchURL = (url = null) => urlEnvMap[environmentMapper(url)] || searchURL;

export const api = {
  async get(url) {
    const { searchURLHeaders } = brandingImporter();
    const options = {
      method: "GET",
      headers: searchURLHeaders,
    };
    let data, error;
    fetch(url, options)
      .then((response) => response.json())
      .then((fetchData) => (data = fetchData))
      .catch(err => (error = err));

    return [error, data];
  },
};
