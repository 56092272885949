/** @module @sbx/prec-shared-utils */

export * from './brandingUtils';
export * from './fontUtils';
export * from './cookieUtils';
export * from './urlUtils';
export * from './currencies/currencies';
export * from './localization/environments';
export * from './localization/supportedLocales';
export * from './searchApiUtils';
export * from './domUtils';
export * from './thumbor';

export function to(promise) {
  return promise
    .then(data => {
      return [null, data];
    })
    .catch(err => [err]);
}
