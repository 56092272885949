export const merchSubCategoryV2 = {
  route: () => import('./MerchSubCategoryV2'),
  propTypes: {
    fontColor: {
      type: String,
      default: '#ffffff',
    },
    items: {
      required: true,
      type: Object,
    },
  },

  devProps: {
    fontColor: '#ffffff',
    items: {
      leftColumn: [
        {
          image: {
            mobile: 'https://media.smartbox.com/media/it/test/wellness/IT_Adventure_MerchBlock_1_351x217.jpg',
            base: 'https://media.smartbox.com/media/it/test/wellness/IT_Adventure_MerchBlock_1_630x390.jpg',
          },
          url: 'https://www.smartbox.com/fr/sport-et-aventure/aeriens/',
          title: 'Activités aériennes',
          subtitle: 'à partir de 29,90 €',
          backgroundColor: '#00C4B3',
        },
        {
          image: {
            mobile: 'https://media.smartbox.com/media/it/test/wellness/IT_Adventure_MerchBlock_4_351x217.jpg',
            base: 'https://media.smartbox.com/media/it/test/wellness/IT_Adventure_MerchBlock_4_630x216.jpg',
          },
          url: 'https://www.smartbox.com/fr/sport-et-aventure/terrestres/',
          title: 'Aventures terrestres',
          subtitle: 'à partir de 32,90 €',
          backgroundColor: '#F7A600',
        },
      ],
      rightColumn: [
        {
          image: {
            mobile: 'https://media.smartbox.com/media/it/test/wellness/IT_Adventure_MerchBlock_2_351x217.jpg',
            base: 'https://media.smartbox.com/media/it/test/wellness/IT_Adventure_MerchBlock_2_630x216.jpg',
          },
          url: 'https://www.smartbox.com/fr/sport-et-aventure/aquatiques/',
          title: 'Sports aquatiques',
          subtitle: 'à partir de 24,90 €',
          backgroundColor: '#E4003A',
        },
        {
          image: {
            mobile: 'https://media.smartbox.com/media/it/2021/hub-pages/IT_Adventure_MerchBlock_6_351x217.jpg',
            base: 'https://media.smartbox.com/media/it/2021/hub-pages/IT_Adventure_MerchBlock_6_630x390.jpg',
          },
          url: 'https://www.smartbox.com/fr/sport-et-aventure/aquatiques/',
          title: '100% adrénaline',
          subtitle: 'à partir de 23 €',
          backgroundColor: '#822B86',
        },
      ],
    },
  },
};
