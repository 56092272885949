import { currencyFormat } from './currencyFormat';
import { currencyStyles } from './constants';

/***
 * @private
 * @param {string} brand The used currency (€, CHF, kr)
 * @param {string} locale The delimiter for decimals
 * @returns {object}
 * @description Finds currencyFormat from locale. Note: exported as 'currency' in the brandingImporter object
 */
export function getLocaleCurrencyFormat(brand, locale) {
  const localeFormat = currencyFormat.find(item => {
    return item.brand === brand && item.locale === locale;
  });

  if (!localeFormat) {
    logInvalidCombinationError(brand, locale);
    return {};
  }

  return localeFormat;
}

/***
 * @param {number} value
 * @param {object} options
 * @param {string} options.currency The used currency (€, CHF, kr)
 * @param {string} options.delimiter The delimiter for decimals
 * @param {string} options.thousands The separator for thousands
 * @param {string} options.currencyStyle How the currency symbol is displayed
 * @returns {string}
 * @description Formats 'value' to a currency. Negative values are treated as positive. currencyInfo can be grabbed from the brandingImporter obejct
 */
export function formatPrice(value, currencyInfo) {
  if (value == 0) return '0';
  if (!value) return '';

  // round the value to 2 decimals and use the absolute value
  const stringValue = Math.abs(value.toFixed(2)).toString();
  const splitValue = stringValue.split('.');
  const wholeValue = getWholeValue(splitValue[0], currencyInfo.thousands);
  const decimalValue = getDecimalValue(splitValue[1]);

  const formattedValue = wholeValue + currencyInfo.delimiter + decimalValue;

  switch (currencyInfo.currencyStyle) {
    case currencyStyles.pre:
      return currencyInfo.currency + ' ' + formattedValue;
    case currencyStyles.post:
      return formattedValue + currencyInfo.currency;
    default:
      return formattedValue + ' ' + currencyInfo.currency;
  }
}

/***
 * @private
 * @param {string} value not null
 * @param {string} thousands
 * @returns {string}
 */
function getWholeValue(value, thousands) {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
}

/***
 * @private
 * @param {string} value not null
 * @returns {string}
 */
function getDecimalValue(value) {
  let returnedValue = value || '';
  while (returnedValue.length < 2) {
    returnedValue += '0';
  }

  return returnedValue;
}

/***
 * @private
 * @param {string} brand
 * @param {string} locale
 * @returns {string}
 */
function logInvalidCombinationError(brand, locale) {
  console.error(`Invalid brand/locale combination {brand: '${brand}'', locale: '${locale}''}.`);
}
