export const subCategoriesLinksV2 = {
  route: () => import('./SubCategoriesLinksV2.vue'),
  propTypes: {
    subCatAspect: {
      type: String,
      default: 'pill',
      validator: function (value) {
        return ['pill', 'button'].includes(value);
      },
    },
    title: {
      type: String,
      default: "Explora todos nuestros universos"
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        title: 'Ferrari',
        link: 'nos-smartbox/sejour/',
        color: 'red',
      },
      {
        title: 'Porsche',
        link: 'nos-smartbox/bien-etre/',
        color: 'blue',
      },
      {
        title: 'Barcelona',
        link: 'nos-smartbox/gastronomie/',
        color: 'pink',
      },
      {
        title: 'Lamborghini',
        link: 'nos-smartbox/gastronomie/',
        color: 'orange',
      },
      {
        title: 'Subaru',
        link: 'nos-smartbox/gastronomie/',
        color: 'red',
      },
    ]
  }
};
