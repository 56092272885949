export const recentlyViewed = {
  route: () => import('./RecentlyViewed.vue'),
  propTypes: {
    title: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
};
